/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
/*
  This file is generated by schemagen.js. Do not edit it directly.
*/
import { z } from 'zod';
import { cloudinaryField } from '../../../../scripts/schemaGenerator/fields/cloudinaryField';
import { contentStackJSONRichText } from '../../../../scripts/schemaGenerator/fields/rte';
import { fileField } from '../../../../scripts/schemaGenerator/fields/fileField';
import { linkField } from '../../../../scripts/schemaGenerator/fields/linkField';

const global_accordion_group = z.object({
  alignment: z.enum(['center', 'left']).nullable().default(null),
  module_title: z
    .object({
      major: contentStackJSONRichText,
      title: contentStackJSONRichText,
      subtitle: contentStackJSONRichText,
      title_order: z
        .enum(['major-first', 'minor-first'])
        .nullable()
        .default('major-first'),
    })
    .default({}),
  accordion_items: z
    .array(
      z.object({
        heading: z.string().default(''),
        body: contentStackJSONRichText,
        default_is_expanded: z.boolean().default(false),
      }),
    )
    .default([]),
  module_footer: z
    .object({
      link: linkField.default({}),
      hide_on_mobile: z.boolean().default(false),
      button_color: z.enum(['mint', 'navy', 'citrus']).default('citrus'),
    })
    .default({}),
});
const global_animated_grid = z.object({
  alignment: z.enum(['center', 'left']).nullable().default(null),
  module_title: z
    .object({
      major: contentStackJSONRichText,
      title: contentStackJSONRichText,
      subtitle: contentStackJSONRichText,
      title_order: z
        .enum(['major-first', 'minor-first'])
        .nullable()
        .default(null),
    })
    .default({}),
  items: z
    .array(
      z.object({
        label: contentStackJSONRichText,
        description: contentStackJSONRichText,
        top_image: z
          .object({ src: cloudinaryField, alt: z.string().default('') })
          .default({}),
        bottom_image: z
          .object({ src: cloudinaryField, alt: z.string().default('') })
          .default({}),
      }),
    )
    .default([]),
  module_footer: z
    .object({
      link: linkField.default({}),
      hide_on_mobile: z.boolean().default(false),
      button_color: z.enum(['mint', 'navy', 'citrus']).default('citrus'),
    })
    .default({}),
});
const global_business_line_carousel = z.object({
  header: z
    .object({
      title: z.string().default(''),
      description: z.string().default(''),
      description_links: z.array(linkField).default([]),
    })
    .default({}),
  business_lines: z
    .array(
      z.object({
        image: z
          .object({ alt: z.string().default(''), src: cloudinaryField })
          .default({}),
        link: linkField.default({}),
      }),
    )
    .default([]),
});
const global_content_gallery = z.object({
  layout: z.enum(['short', 'tall']).default('tall'),
  header: z
    .object({
      title: z.string().default(''),
      description: z.string().default(''),
      description_links: z.array(linkField).default([]),
    })
    .default({}),
  text: contentStackJSONRichText,
  top_left_image: z
    .object({ source: cloudinaryField, alt_text: z.string().default('') })
    .default({}),
  top_right_image: z
    .object({ source: cloudinaryField, alt_text: z.string().default('') })
    .default({}),
  bottom_left_image: z
    .object({ source: cloudinaryField, alt_text: z.string().default('') })
    .default({}),
  bottom_right_image: z
    .object({ source: cloudinaryField, alt_text: z.string().default('') })
    .default({}),
});
const global_display_carousel = z.object({
  alignment: z.enum(['center', 'left']).nullable().default(null),
  module_title: z
    .object({
      major: contentStackJSONRichText,
      title: contentStackJSONRichText,
      subtitle: contentStackJSONRichText,
      title_order: z
        .enum(['major-first', 'minor-first'])
        .nullable()
        .default('major-first'),
    })
    .default({}),
  slide_width: z.enum(['small', 'medium', 'large']).default('medium'),
  slides: z
    .array(
      z.object({
        image: z
          .object({ source: cloudinaryField, alt: z.string().default('') })
          .default({}),
        content: z
          .object({
            text: contentStackJSONRichText,
            placement: z.enum(['above', 'below']).nullable().default(null),
          })
          .default({}),
      }),
    )
    .default([]),
  module_footer: z
    .object({
      link: linkField.default({}),
      hide_on_mobile: z.boolean().default(false),
      button_color: z.enum(['mint', 'navy', 'citrus']).default('citrus'),
    })
    .default({}),
});
const global_display_grid = z.object({
  alignment: z.enum(['center', 'left']).nullable().default(null),
  content_background: z
    .enum(['white', 'gray-99', 'mint-60', 'blue-45'])
    .nullable()
    .default('white'),
  module_title: z
    .object({
      major: contentStackJSONRichText,
      title: contentStackJSONRichText,
      subtitle: contentStackJSONRichText,
      title_order: z
        .enum(['major-first', 'minor-first'])
        .nullable()
        .default('major-first'),
    })
    .default({}),
  grid_items: z
    .array(
      z.object({
        image: z
          .object({ source: cloudinaryField, alt: z.string().default('') })
          .default({}),
        content: z
          .object({
            label: contentStackJSONRichText,
            text: contentStackJSONRichText,
            placement: z.enum(['above', 'below']).nullable().default(null),
          })
          .default({}),
      }),
    )
    .default([]),
  module_footer: z
    .object({
      link: linkField.default({}),
      hide_on_mobile: z.boolean().default(false),
      button_color: z.enum(['mint', 'navy', 'citrus']).default('citrus'),
    })
    .default({}),
});
const global_display_panel = z.object({
  module_title: z
    .object({
      major: contentStackJSONRichText,
      title: contentStackJSONRichText,
      subtitle: contentStackJSONRichText,
      title_order: z
        .enum(['major-first', 'minor-first'])
        .nullable()
        .default('major-first'),
    })
    .default({}),
  media: z
    .object({ src: cloudinaryField, alt: z.string().default('') })
    .default({}),
  text: contentStackJSONRichText,
  avatar_image: z
    .object({ src: cloudinaryField, alt: z.string().default('') })
    .default({}),
  attribution: contentStackJSONRichText,
  content_order: z.enum(['mediaFirst', 'textFirst']).default('mediaFirst'),
  module_footer: z
    .object({
      link: linkField.default({}),
      hide_on_mobile: z.boolean().default(false),
      button_color: z.enum(['mint', 'navy', 'citrus']).default('citrus'),
    })
    .default({}),
});
const global_display_split = z.object({
  text: contentStackJSONRichText,
  media: z
    .object({ alt: z.string().default(''), src: cloudinaryField })
    .default({}),
  content_order: z.enum(['mediaFirst', 'textFirst']).default('mediaFirst'),
  variant: z.enum(['mint', 'blue', 'grey']).default('mint'),
});
const global_display_text = z.object({
  section_title: z.string().default(''),
  text_items: z
    .array(
      z.object({
        text: z.string().default(''),
        family: z.enum(['medium', 'regular']).default('regular'),
      }),
    )
    .default([]),
  link: linkField.default({}),
});
const global_divider = z.object({
  variant: z
    .enum(['section', 'statement', 'item', 'subtle'])
    .default('section'),
});
const global_faqs = z.object({
  faq: z
    .array(
      z.object({
        question: z.string().default(''),
        answer: z.string().default(''),
        link_href: linkField.default({}),
      }),
    )
    .default([]),
});
const global_footer_image_links = z.object({
  title: z.string().default(''),
  links: z
    .array(
      z.object({
        caption: z.string().default(''),
        href: z.string().default(''),
        image: z
          .object({ alt_text: z.string().default(''), src: cloudinaryField })
          .default({}),
      }),
    )
    .default([]),
});
const global_gallery_hero = z.object({
  items: z
    .array(
      z.object({
        minor_title: z.string().default(''),
        major_title: z.string().default(''),
        mobile_image: z
          .object({ src: cloudinaryField, alt: z.string().default('') })
          .default({}),
        desktop_image: z
          .object({ src: cloudinaryField, alt: z.string().default('') })
          .default({}),
        cta: linkField.default({}),
      }),
    )
    .default([]),
  layout: z.enum(['full', 'split']).default('full'),
  button_color: z.enum(['mint', 'navy', 'citrus']).default('citrus'),
});
const global_get_started_box = z.object({
  title: z.string().default(''),
  cta: linkField.default({}),
});
const global_headline = z.object({ headline: z.string().default('') });
const global_hero = z.object({
  title: contentStackJSONRichText,
  subtitle: contentStackJSONRichText,
  font_color: z.enum(['blue-45', 'gray-16', 'white']).default('white'),
  content_background: z.enum(['none', 'white']).default('none'),
  cta: linkField.default({}),
  media: z
    .object({
      mobile: z
        .object({ src: cloudinaryField, alt: z.string().default('') })
        .default({}),
      desktop: z
        .object({ src: cloudinaryField, alt: z.string().default('') })
        .default({}),
    })
    .default({}),
});
const global_image_content_1up = z.object({
  title: z.string().default(''),
  items: z
    .array(
      z.object({
        title: z.string().default(''),
        description: z.string().default(''),
      }),
    )
    .default([]),
  image: z
    .object({ src: cloudinaryField, alt: z.string().default('') })
    .default({}),
  show_items_divider: z.boolean().default(false),
  image_placement: z.enum(['left', 'right']).default('left'),
});
const global_image_half_screen_hero = z.object({
  eyebrow: z.string().default(''),
  title: z.string().default(''),
  description: z.string().default(''),
  cta_text: z.string().default(''),
  cta_href: z.string().default(''),
  sign_in_url: z.string().default(''),
  image: z
    .object({ alt: z.string().default(''), src: cloudinaryField })
    .default({}),
  variant: z.enum(['gray', 'mint']).nullable().default(null),
  content_order: z.enum(['textFirst', 'mediaFirst']).nullable().default(null),
});
const global_interactive_carousel = z.object({
  module_title: z
    .object({
      major: contentStackJSONRichText,
      title: contentStackJSONRichText,
      subtitle: contentStackJSONRichText,
      title_order: z
        .enum(['major-first', 'minor-first'])
        .nullable()
        .default('major-first'),
    })
    .default({}),
  module_alignment: z.enum(['left', 'center']).default('left'),
  slide_width: z.enum(['small', 'medium', 'large']).default('medium'),
  slides: z
    .array(
      z.union([
        z.object({
          image_slide: z.object({
            src: cloudinaryField,
            alt: z.string().default(''),
            label: z.string().default(''),
            href: z.string().default(''),
          }),
        }),
        z.object({
          video_slide: z.object({
            video_src: cloudinaryField,
            video_description: z.string().default(''),
            label: z.string().default(''),
            href: z.string().default(''),
          }),
        }),
      ]),
    )
    .default([]),
  module_footer: z
    .object({
      link: linkField.default({}),
      hide_on_mobile: z.boolean().default(false),
      button_color: z.enum(['mint', 'navy', 'citrus']).default('citrus'),
    })
    .default({}),
});
const global_interactive_grid = z.object({
  module_title: z
    .object({
      major: contentStackJSONRichText,
      title: contentStackJSONRichText,
      subtitle: contentStackJSONRichText,
      title_order: z
        .enum(['major-first', 'minor-first'])
        .nullable()
        .default('major-first'),
    })
    .default({}),
  grid_items: z
    .array(
      z.object({
        image: z
          .object({ source: cloudinaryField, alt: z.string().default('') })
          .default({}),
        label: z.string().default(''),
        href: z.string().default(''),
      }),
    )
    .default([]),
  module_footer: z
    .object({
      link: linkField.default({}),
      hide_on_mobile: z.boolean().default(false),
      button_color: z.enum(['mint', 'navy', 'citrus']).default('citrus'),
    })
    .default({}),
});
const global_logo_grid = z.object({
  header: z
    .object({
      title: z.string().default(''),
      description: z.string().default(''),
      description_links: z.array(linkField).default([]),
    })
    .default({}),
  logos: z
    .array(z.object({ src: cloudinaryField, alt_text: z.string().default('') }))
    .default([]),
  column_count: z.number().nullable().default(8),
});
const global_media_gallery = z.object({
  module_title: z
    .object({
      major: contentStackJSONRichText,
      minor: contentStackJSONRichText,
      subtitle: contentStackJSONRichText,
      title_order: z
        .enum(['major-first', 'minor-first'])
        .nullable()
        .default(null),
    })
    .default({}),
  items: z
    .array(
      z.object({
        media: z
          .object({
            mobile: z
              .object({ src: cloudinaryField, alt: z.string().default('') })
              .default({}),
            desktop: z
              .object({ src: cloudinaryField, alt: z.string().default('') })
              .default({}),
          })
          .default({}),
        content: contentStackJSONRichText,
        type: z
          .enum(['full-bleed', 'media-stacked'])
          .nullable()
          .default('full-bleed'),
      }),
    )
    .default([]),
  module_footer: z
    .object({
      link: linkField.default({}),
      hide_on_mobile: z.boolean().default(false),
      button_color: z.enum(['mint', 'navy', 'citrus']).default('citrus'),
    })
    .default({}),
});
const global_merch_carousel = z.object({
  header: z
    .object({
      title: z.string().default(''),
      description: z.string().default(''),
      description_links: z.array(linkField).default([]),
    })
    .default({}),
  items: z
    .array(
      z.object({
        image: z
          .object({ src: cloudinaryField, alt: z.string().default('') })
          .default({}),
        link: linkField.default({}),
        top_text: z.string().default(''),
        bottom_text: z.string().default(''),
        text_background_color: z.enum(['white']).default('white'),
      }),
    )
    .default([]),
});
const global_promo_banner = z.object({
  headline: z.string().default(''),
  content: z.string().default(''),
  cta_text: z.string().default(''),
  cta_href: z.string().default(''),
  images: z
    .array(z.object({ alt: z.string().default(''), src: cloudinaryField }))
    .default([]),
  variant: z
    .enum(['teal', 'dark-teal', 'charcoal', 'coastal', 'light-gray'])
    .default('charcoal'),
});
const global_reasons_to_signup = z.object({
  title: z.string().default(''),
  blocks: z
    .array(
      z.object({
        illustration: z
          .enum([
            'IllustrationMdHiwStylePreferences',
            'IllustrationMdHiwStylistSelections',
            'IllustrationMdFreestyleBagsPair',
            'IllustrationSmNoCommitmentScissors',
            'IllustrationSmValuePropFreeShipping',
            'IllustrationSmExpertPick',
          ])
          .nullable()
          .default(null),
        title: z.string().default(''),
        description: z.string().default(''),
      }),
    )
    .default([]),
});
const global_seo_data = z.object({
  meta_title: z.string().default('Your Online Personal Stylist | Stitch Fix'),
  meta_description: z.string().default(''),
  open_graph_title: z.string().default(''),
  open_graph_description: z.string().default(''),
  open_graph_image: cloudinaryField,
  meta_robots: z.string().default(''),
  canonical_url: z.string().default(''),
  google_validation: z.string().default(''),
  bing_validation: z.string().default(''),
  href_lang: z
    .array(
      z.object({ lang: z.string().default(''), href: z.string().default('') }),
    )
    .default([]),
});
const global_shoppable_editorial = z.object({
  title: z.string().default(''),
  description: z.string().default(''),
  cover_item: z
    .object({
      image: z
        .object({ src: cloudinaryField, alt: z.string().default('') })
        .default({}),
      href: linkField.default({}),
      product_name: z.string().default(''),
      product_brand: z.string().default(''),
      product_price: z.string().default(''),
    })
    .default({}),
  items: z
    .array(
      z.object({
        image: z
          .object({ src: cloudinaryField, alt: z.string().default('') })
          .default({}),
        href: linkField.default({}),
        product_name: z.string().default(''),
        product_brand: z.string().default(''),
        product_price: z.string().default(''),
      }),
    )
    .default([]),
});
const global_simple_image = z.object({
  cloud: cloudinaryField,
  file: fileField,
});
const global_sticky_cta = z.object({
  cta: linkField.default({}),
  button_color: z.enum(['mint', 'navy', 'citrus']).default('citrus'),
});
const global_style_shuffle = z.object({
  title: z.string().default(''),
  header: z.string().default(''),
  description: z.string().default(''),
  images: z
    .array(z.object({ alt: z.string().default(''), src: cloudinaryField }))
    .default([]),
  end_card_cta_href: z.string().default(''),
});
const global_stylist_module = z.object({
  header: z.string().default(''),
  description: z.string().default(''),
  image: z
    .object({ src: cloudinaryField, alt_text: z.string().default('') })
    .default({}),
  testimonials: z
    .array(
      z.object({
        quote: z.string().default(''),
        source: z.string().default(''),
        role: z.string().default(''),
      }),
    )
    .default([]),
});
const global_test_global_field = z.object({
  headline: z.string().default(''),
  blurb: z.string().default(''),
});
const global_trigall_hero = z.object({
  mobile_image: z
    .object({ src: cloudinaryField, alt: z.string().default('') })
    .default({}),
  left_image: z
    .object({ src: cloudinaryField, alt: z.string().default('') })
    .default({}),
  title: z.string().default(''),
  description: z.string().default(''),
  button: linkField.default({}),
  top_right_image: z
    .object({ src: cloudinaryField, alt: z.string().default('') })
    .default({}),
  bottom_right_image: z
    .object({ src: cloudinaryField, alt: z.string().default('') })
    .default({}),
});
const global_triple_block = z.object({
  title: z.string().default(''),
  block_size: z.enum(['sm', 'md', 'lg']).default('md'),
  block: z
    .array(
      z.object({
        image: z
          .object({ src: cloudinaryField, alt: z.string().default('') })
          .default({}),
        illustration: z
          .enum([
            'IllustrationMdHiwStylePreferences',
            'IllustrationMdHiwStylistSelections',
            'IllustrationMdFreestyleBagsPair',
            'IllustrationSmNoCommitmentScissors',
            'IllustrationSmValuePropFreeShipping',
            'IllustrationSmExpertPick',
            'IllustrationMdFeatureFixes',
          ])
          .nullable()
          .default(null),
        title: z.string().default(''),
        description: z.string().default(''),
      }),
    )
    .default([]),
});
const global_video_full_bleed_hero = z.object({
  title: z
    .object({
      title_text: z.string().default(''),
      title_shadow: z.boolean().default(false),
      hide_title_from_view: z.boolean().default(false),
    })
    .default({}),
  cta_text: z.string().default(''),
  cta_href: z.string().default(''),
  content_order: z
    .enum(['Title/CTA first', 'Video first'])
    .nullable()
    .default(null),
  mobile_video: cloudinaryField,
  tablet_video: cloudinaryField,
  desktop_video: cloudinaryField,
  video_description: z.string().default(''),
  captions: z
    .object({
      language: z.enum(['en']).default('en'),
      label: z.string().default(''),
      file: cloudinaryField,
      default: z.boolean().default(false),
    })
    .default({}),
  sound_controls: z.boolean().default(false),
  play_pause_button_size: z.enum(['large', 'small']).nullable().default(null),
});
const global_video_half_screen_hero = z.object({
  eyebrow: z.string().default(''),
  title: z.string().default(''),
  description: z.string().default(''),
  cta_text: z.string().default(''),
  cta_href: z.string().default(''),
  sign_in_url: z.string().default(''),
  mobile_video: cloudinaryField,
  tablet_video: cloudinaryField,
  desktop_video: cloudinaryField,
  video_description: z.string().default(''),
  captions: z
    .object({
      language: z.enum(['en']).default('en'),
      label: z.string().default(''),
      file: cloudinaryField,
      default: z.boolean().default(false),
    })
    .default({}),
  sound_controls: z.boolean().default(false),
  variant: z.enum(['gray', 'mint']).default('mint'),
  content_order: z.enum(['textFirst', 'mediaFirst']).nullable().default(null),
  play_pause_button_size: z.enum(['large', 'small']).nullable().default(null),
});
const about = z.object({
  title: z.string().default(''),
  seo_data: global_seo_data.default({}),
  image_half_screen_hero: global_image_half_screen_hero.default({}),
  content: contentStackJSONRichText,
  executive_team: z
    .object({
      heading: z.string().default(''),
      executive_bios: z
        .array(
          z.object({
            name: z.string().default(''),
            title: z.string().default(''),
            role: z.string().default(''),
            description: z.string().default(''),
            image: z
              .object({
                alt_text: z.string().default(''),
                src: cloudinaryField,
              })
              .default({}),
          }),
        )
        .default([]),
    })
    .default({}),
  board: z
    .object({
      heading: z.string().default(''),
      board_bios: z
        .array(
          z.object({
            name: z.string().default(''),
            title: z.string().default(''),
            role: z.string().default(''),
            description: z.string().default(''),
            image: z
              .object({
                alt_text: z.string().default(''),
                src: cloudinaryField,
              })
              .default({}),
          }),
        )
        .default([]),
    })
    .default({}),
  get_started: global_get_started_box.default({}),
});
const careers = z.object({
  title: z.string().default(''),
  seo_data: global_seo_data.default({}),
  careers_hero: z
    .object({
      title: z.string().default(''),
      subtitle: z.string().default(''),
      cta: z
        .object({ text: z.string().default(''), href: z.string().default('') })
        .default({}),
      desktop_image: z
        .object({ src: cloudinaryField, alt: z.string().default('') })
        .default({}),
      mobile_image: z
        .object({ src: cloudinaryField, alt: z.string().default('') })
        .default({}),
    })
    .default({}),
  full_width_content: z
    .object({
      title: z.string().default(''),
      description: z.string().default(''),
      list: z
        .array(
          z.object({
            title: z.string().default(''),
            description: z.string().default(''),
          }),
        )
        .default([]),
    })
    .default({}),
  triple_block: global_triple_block.default({}),
  content_gallery: global_content_gallery.default({}),
  quotes: z
    .object({
      quotes: z
        .array(
          z.object({
            text: z.string().default(''),
            name: z.string().default(''),
            title: z.string().default(''),
          }),
        )
        .default([]),
    })
    .default({}),
  total_rewards: z
    .object({
      title: z.string().default(''),
      blocks: z
        .array(
          z.object({
            title: z.string().default(''),
            description: z.string().default(''),
            icon: z
              .enum([
                'hanger-heart',
                'camera',
                'box-delivery',
                'learning',
                'collaborative',
                'compensation',
                'discounts',
                'vacation',
                'wellness',
              ])
              .nullable()
              .default(null),
          }),
        )
        .default([]),
      bg_color: z.enum(['light', 'dark']).nullable().default(null),
      link: z
        .object({ title: z.string().default(''), href: z.string().default('') })
        .default({}),
    })
    .default({}),
  join_us: z
    .object({
      title: z.string().default(''),
      cta: z
        .object({ text: z.string().default(''), href: z.string().default('') })
        .default({}),
    })
    .default({}),
  follow_us: z
    .object({
      title: z.string().default(''),
      subtitle: z.string().default(''),
      images: z
        .array(
          z.object({
            src: cloudinaryField,
            alt: z.string().default(''),
            name: z.string().default(''),
          }),
        )
        .default([]),
      show_social_icons: z.boolean().default(false),
      bg_color: z.string().default(''),
    })
    .default({}),
  applicant_privacy_policy: z
    .object({
      text: z.string().default(''),
      link: z
        .object({ text: z.string().default(''), href: z.string().default('') })
        .default({}),
    })
    .default({}),
});
const find_your_perfect_fit = z.object({
  title: z.string().default(''),
  seo_data: global_seo_data.default({}),
  hero: global_trigall_hero.default({}),
  how_it_works: global_triple_block.default({}),
  content_gallery: global_content_gallery.default({}),
  categories_and_sizes: z
    .object({
      clothing_categories_title: z.string().default(''),
      clothing_categories: z.array(z.string()).default([]),
      sizes_title: z.string().default(''),
      sizes: z.array(z.string()).default([]),
      bottom_copy: z.string().default(''),
      description: z.string().default(''),
      description_links: z
        .array(
          z.object({
            href: z.string().default(''),
            text: z.string().default(''),
          }),
        )
        .default([]),
      title: z.string().default(''),
    })
    .default({}),
  free_and_easy_exchanges: z
    .object({
      description: z.string().default(''),
      title: z.string().default(''),
      description_links: z
        .array(
          z.object({
            href: z.string().default(''),
            text: z.string().default(''),
          }),
        )
        .default([]),
    })
    .default({}),
  get_tips_from_our_experts: z
    .object({
      description: z.string().default(''),
      title: z.string().default(''),
      description_links: z
        .array(
          z.object({
            href: z.string().default(''),
            text: z.string().default(''),
          }),
        )
        .default([]),
    })
    .default({}),
  womens_tips_links: global_footer_image_links.default({}),
  mens_tips_links: global_footer_image_links.default({}),
  testimonials: z
    .object({
      title: z.string().default(''),
      description: z.string().default(''),
      testimonials: z
        .array(
          z.object({
            title: z.string().default(''),
            quote: z.string().default(''),
            label: z.string().default(''),
            source: z.string().default(''),
            image: z
              .object({ alt: z.string().default(''), src: cloudinaryField })
              .default({}),
          }),
        )
        .default([]),
    })
    .default({}),
});
const gateway = z.object({
  title: z.string().default(''),
  seo_data: global_seo_data.default({}),
  video_full_bleed_hero: global_video_full_bleed_hero.default({}),
  image_half_screen_hero: global_image_half_screen_hero.default({}),
  how_it_works: global_triple_block.default({}),
  business_line_carousel: global_business_line_carousel.default({}),
  logos: global_logo_grid.default({}),
  stylist_module: global_stylist_module.default({}),
  reasons_to_signup: global_reasons_to_signup.default({}),
  get_started: global_get_started_box.default({}),
  promo_banner: global_promo_banner.default({}),
  faqs: global_faqs.default({}),
});
const gateway_experiment = z.object({
  title: z.string().default(''),
  seo_data: global_seo_data.default({}),
  video_full_bleed_hero: global_video_full_bleed_hero.default({}),
  image_half_screen_hero: global_image_half_screen_hero.default({}),
  how_it_works: global_triple_block.default({}),
  business_line_carousel: global_business_line_carousel.default({}),
  logos: global_logo_grid.default({}),
  stylist_module: global_stylist_module.default({}),
  reasons_to_signup: global_reasons_to_signup.default({}),
  get_started: global_get_started_box.default({}),
  promo_banner: global_promo_banner.default({}),
  faqs: global_faqs.default({}),
});
const how_it_works = z.object({
  title: z.string().default(''),
  seo_data: global_seo_data.default({}),
  hero: global_trigall_hero.default({}),
  header: z
    .object({
      title: z.string().default(''),
      description: z.string().default(''),
      description_links: z
        .array(
          z.object({
            link: z
              .object({
                href: z.string().default(''),
                text: z.string().default(''),
              })
              .default({}),
          }),
        )
        .default([]),
    })
    .default({}),
  how_it_works: z
    .object({
      title: z.string().default(''),
      learn_more: z.string().default(''),
      steps: z
        .array(
          z.object({
            title: z.string().default(''),
            description: z.string().default(''),
            icon: z
              .enum([
                'IntroduceYourself',
                'GetStarted',
                'BuyWhatYouLove',
                'SendBackTheRest',
                'LoveYourLook',
              ])
              .default('IntroduceYourself'),
          }),
        )
        .default([]),
    })
    .default({}),
  testimonials: z
    .object({
      testimonals: z
        .array(
          z.object({
            testimonial: z
              .object({
                image: z
                  .object({ src: cloudinaryField, alt: z.string().default('') })
                  .default({}),
                quote: z.string().default(''),
                label: z.string().default(''),
                source: z.string().default(''),
                title: z.string().default(''),
              })
              .default({}),
          }),
        )
        .default([]),
    })
    .default({}),
  objectives: z
    .object({
      header_props: z.object({ title: z.string().default('') }).default({}),
      blocks: z
        .array(
          z.object({
            block: z
              .object({
                title: z.string().default(''),
                description: z.string().default(''),
              })
              .default({}),
          }),
        )
        .default([]),
    })
    .default({}),
  content_gallery: global_content_gallery.default({}),
});
const kids = z.object({
  title: z.string().default(''),
  seo_data: global_seo_data.default({}),
  video_half_screen_hero: global_video_half_screen_hero.default({}),
  image_half_screen_hero: global_image_half_screen_hero.default({}),
  how_it_works: global_triple_block.default({}),
  image_content_1up: global_image_content_1up.default({}),
  merch_carousel: global_merch_carousel.default({}),
  logos: global_logo_grid.default({}),
  get_started: global_get_started_box.default({}),
});
const landing_page_custom = z.object({
  title: z.string().default(''),
  url: z.string().default(''),
  seo_data: global_seo_data.default({}),
  modules: z
    .array(
      z.union([
        z.object({ business_line_carousel: global_business_line_carousel }),
        z.object({
          content_block: z.object({ content: contentStackJSONRichText }),
        }),
        z.object({ content_gallery: global_content_gallery }),
        z.object({ divider: global_divider }),
        z.object({ faqs: global_faqs }),
        z.object({ footer_image_links: global_footer_image_links }),
        z.object({ get_started: global_get_started_box }),
        z.object({ image_content_1up: global_image_content_1up }),
        z.object({ image_half_screen_hero: global_image_half_screen_hero }),
        z.object({ logo_grid: global_logo_grid }),
        z.object({ merch_carousel: global_merch_carousel }),
        z.object({ promo_banner: global_promo_banner }),
        z.object({ reasons_to_signup: global_reasons_to_signup }),
        z.object({ shoppable_editorial: global_shoppable_editorial }),
        z.object({ stylist_module: global_stylist_module }),
        z.object({ style_shuffle: global_style_shuffle }),
        z.object({ trigall_hero: global_trigall_hero }),
        z.object({ triple_block: global_triple_block }),
        z.object({ video_full_bleed_hero: global_video_full_bleed_hero }),
        z.object({ video_half_screen_hero: global_video_half_screen_hero }),
      ]),
    )
    .default([]),
});
const men = z.object({
  title: z.string().default(''),
  seo_data: global_seo_data.default({}),
  video_half_screen_hero: global_video_half_screen_hero.default({}),
  image_half_screen_hero: global_image_half_screen_hero.default({}),
  how_it_works: global_triple_block.default({}),
  merch_carousel: global_merch_carousel.default({}),
  logos: global_logo_grid.default({}),
  stylist_module: global_stylist_module.default({}),
  style_shuffle: global_style_shuffle.default({}),
  reasons_to_signup: global_reasons_to_signup.default({}),
  get_started: global_get_started_box.default({}),
  promo_banner: global_promo_banner.default({}),
  faqs: global_faqs.default({}),
});
const men_big_tall = z.object({
  title: z.string().default(''),
  seo_data: global_seo_data.default({}),
  trigal_hero: global_trigall_hero.default({}),
  pricing: z
    .object({
      title: z.string().default(''),
      description: z.string().default(''),
      questions: z
        .array(
          z.object({
            question: z.string().default(''),
            answer: z.string().default(''),
            link: z
              .object({
                text: z.string().default(''),
                href: z.string().default(''),
              })
              .default({}),
          }),
        )
        .default([]),
    })
    .default({}),
  best_fit: z
    .object({
      title: z.string().default(''),
      items: z.array(z.string()).default([]),
      left_image: z
        .object({ src: cloudinaryField, alt: z.string().default('') })
        .default({}),
      right_image: z
        .object({ src: cloudinaryField, alt: z.string().default('') })
        .default({}),
      modifier: z.string().default(''),
    })
    .default({}),
  why_love_us: global_content_gallery.default({}),
  how_it_works: global_triple_block.default({}),
  might_also_like_links: global_footer_image_links.default({}),
  recommended_links: global_footer_image_links.default({}),
});
const men_experiment = z.object({
  title: z.string().default(''),
  seo_data: global_seo_data.default({}),
  video_half_screen_hero: global_video_half_screen_hero.default({}),
  image_half_screen_hero: global_image_half_screen_hero.default({}),
  how_it_works: global_triple_block.default({}),
  merch_carousel: global_merch_carousel.default({}),
  logos: global_logo_grid.default({}),
  stylist_module: global_stylist_module.default({}),
  style_shuffle: global_style_shuffle.default({}),
  reasons_to_signup: global_reasons_to_signup.default({}),
  get_started: global_get_started_box.default({}),
  promo_banner: global_promo_banner.default({}),
  faqs: global_faqs.default({}),
});
const modular_page = z.object({
  title: z.string().default(''),
  url: z.string().default(''),
  seo_data: global_seo_data.default({}),
  modular_blocks: z
    .array(
      z.union([
        z.object({ gallery_hero: global_gallery_hero }),
        z.object({ hero: global_hero }),
        z.object({ display_grid: global_display_grid }),
        z.object({ display_carousel: global_display_carousel }),
        z.object({ interactive_grid: global_interactive_grid }),
        z.object({ interactive_carousel: global_interactive_carousel }),
        z.object({ display_panel: global_display_panel }),
        z.object({ display_split: global_display_split }),
        z.object({ display_text: global_display_text }),
        z.object({ accordion_group: global_accordion_group }),
        z.object({ sticky_cta: global_sticky_cta }),
        z.object({ animated_grid: global_animated_grid }),
        z.object({ media_gallery: global_media_gallery }),
      ]),
    )
    .default([]),
});
const pricing = z.object({
  title: z.string().default(''),
  seo_data: global_seo_data.default({}),
  hero: global_trigall_hero.default({}),
  how_it_works: global_triple_block.default({}),
  about_pricing: global_content_gallery.default({}),
  what_clothes_cost: z
    .object({
      title: z.string().default(''),
      description: z.string().default(''),
      description_links: z
        .array(
          z.object({
            text: z.string().default(''),
            href: z.string().default(''),
          }),
        )
        .default([]),
      images: z
        .array(
          z.object({
            mobile: z
              .object({ src: cloudinaryField, alt: z.string().default('') })
              .default({}),
            desktop: z
              .object({ src: cloudinaryField, alt: z.string().default('') })
              .default({}),
          }),
        )
        .default([]),
    })
    .default({}),
  testimonials: z
    .object({
      title: z.string().default(''),
      description: z.string().default(''),
      testimonials: z
        .array(
          z.object({
            title: z.string().default(''),
            quote: z.string().default(''),
            label: z.string().default(''),
            source: z.string().default(''),
            image: z
              .object({ alt: z.string().default(''), src: cloudinaryField })
              .default({}),
          }),
        )
        .default([]),
    })
    .default({}),
});
const style_persona = z.object({
  title: z.string().default(''),
  display_name: z.string().default(''),
  description: z.string().default(''),
  style_senses: z.unknown(),
});
const style_sense = z.object({
  title: z.string().default(''),
  style_sense_id: z.string().default(''),
  display_name: z.string().default(''),
  description: z.string().default(''),
  substyle: z.array(z.string()).default([]),
  business_line: z.enum(['womens', 'mens']).nullable().default(null),
  color: z
    .enum([
      '#DFE6A3',
      '#595E2D',
      '#2E6BF2',
      '#D2E137',
      '#8DA6DD',
      '#246358',
      '#FF5955',
      '#F3ECD3',
      '#7D3736',
      '#86C8BC',
      '#001E60',
      '#DCB6B5',
      '#F0F1F3',
    ])
    .nullable()
    .default(null),
});
const stylefile_sharing_page = z.object({
  title: z.string().default(''),
  seo_data: global_seo_data.default({}),
  primary_cta: z
    .object({
      eyebrow: z.string().default(''),
      link: linkField.default({}),
      variant: z
        .enum(['mint', 'navy-mint', 'navy-citrus', 'citrus', 'grey'])
        .default('navy-mint'),
    })
    .default({}),
  secondary_cta: z
    .object({
      eyebrow: z.string().default(''),
      link: linkField.default({}),
      variant: z
        .enum(['mint', 'navy-mint', 'navy-citrus', 'citrus', 'grey'])
        .default('mint'),
    })
    .default({}),
  how_it_works: z.object({ content: contentStackJSONRichText }).default({}),
});
const women = z.object({
  title: z.string().default(''),
  seo_data: global_seo_data.default({}),
  video_half_screen_hero: global_video_half_screen_hero.default({}),
  image_half_screen_hero: global_image_half_screen_hero.default({}),
  how_it_works: global_triple_block.default({}),
  merch_carousel: global_merch_carousel.default({}),
  logos: global_logo_grid.default({}),
  stylist_module: global_stylist_module.default({}),
  style_shuffle: global_style_shuffle.default({}),
  reasons_to_signup: global_reasons_to_signup.default({}),
  get_started: global_get_started_box.default({}),
  promo_banner: global_promo_banner.default({}),
  faqs: global_faqs.default({}),
});
const women_experiment = z.object({
  title: z.string().default(''),
  seo_data: global_seo_data.default({}),
  video_half_screen_hero: global_video_half_screen_hero.default({}),
  image_half_screen_hero: global_image_half_screen_hero.default({}),
  how_it_works: global_triple_block.default({}),
  merch_carousel: global_merch_carousel.default({}),
  logos: global_logo_grid.default({}),
  stylist_module: global_stylist_module.default({}),
  style_shuffle: global_style_shuffle.default({}),
  reasons_to_signup: global_reasons_to_signup.default({}),
  get_started: global_get_started_box.default({}),
  promo_banner: global_promo_banner.default({}),
  faqs: global_faqs.default({}),
});
const women_jeans = z.object({
  title: z.string().default(''),
  seo_data: global_seo_data.default({}),
  hero: z
    .object({
      title: z.string().default(''),
      label: z.string().default(''),
      description: z.string().default(''),
      images: z
        .array(z.object({ src: cloudinaryField, alt: z.string().default('') }))
        .default([]),
    })
    .default({}),
  how_it_works: global_triple_block.default({}),
  content_gallery: global_content_gallery.default({}),
  fits_and_cuts: z
    .object({
      title: z.string().default(''),
      description: z.string().default(''),
      links: z
        .array(
          z.object({
            path: z.string().default(''),
            text: z.string().default(''),
          }),
        )
        .default([]),
      images: z
        .array(z.object({ src: cloudinaryField, alt: z.string().default('') }))
        .default([]),
    })
    .default({}),
  brand_gallery: z
    .object({
      title: z.string().default(''),
      description: z.string().default(''),
      description_links: z
        .array(
          z.object({
            href: z.string().default(''),
            text: z.string().default(''),
          }),
        )
        .default([]),
      images: z
        .array(z.object({ src: cloudinaryField, alt: z.string().default('') }))
        .default([]),
      double_row: z.boolean().default(false),
    })
    .default({}),
  top_icon_with_copy: z
    .object({ title: z.string().default(''), text: z.string().default('') })
    .default({}),
  get_started_box: global_get_started_box.default({}),
  might_also_love_links: global_footer_image_links.default({}),
  recommended_links: global_footer_image_links.default({}),
});
export type About = z.infer<typeof about>;
export type Careers = z.infer<typeof careers>;
export type FindYourPerfectFit = z.infer<typeof find_your_perfect_fit>;
export type Gateway = z.infer<typeof gateway>;
export type GatewayExperiment = z.infer<typeof gateway_experiment>;
export type HowItWorks = z.infer<typeof how_it_works>;
export type Kids = z.infer<typeof kids>;
export type LandingPageCustom = z.infer<typeof landing_page_custom>;
export type Men = z.infer<typeof men>;
export type MenBigTall = z.infer<typeof men_big_tall>;
export type MenExperiment = z.infer<typeof men_experiment>;
export type ModularPage = z.infer<typeof modular_page>;
export type Pricing = z.infer<typeof pricing>;
export type StylePersona = z.infer<typeof style_persona>;
export type StyleSense = z.infer<typeof style_sense>;
export type StylefileSharingPage = z.infer<typeof stylefile_sharing_page>;
export type Women = z.infer<typeof women>;
export type WomenExperiment = z.infer<typeof women_experiment>;
export type WomenJeans = z.infer<typeof women_jeans>;
export type ContentStackJSONRichText = z.infer<typeof contentStackJSONRichText>;
export type GlobalAccordionGroup = z.infer<typeof global_accordion_group>;
export type GlobalAnimatedGrid = z.infer<typeof global_animated_grid>;
export type GlobalBusinessLineCarousel = z.infer<
  typeof global_business_line_carousel
>;
export type GlobalContentGallery = z.infer<typeof global_content_gallery>;
export type GlobalDisplayCarousel = z.infer<typeof global_display_carousel>;
export type GlobalDisplayGrid = z.infer<typeof global_display_grid>;
export type GlobalDisplayPanel = z.infer<typeof global_display_panel>;
export type GlobalDisplaySplit = z.infer<typeof global_display_split>;
export type GlobalDisplayText = z.infer<typeof global_display_text>;
export type GlobalDivider = z.infer<typeof global_divider>;
export type GlobalFaqs = z.infer<typeof global_faqs>;
export type GlobalFooterImageLinks = z.infer<typeof global_footer_image_links>;
export type GlobalGalleryHero = z.infer<typeof global_gallery_hero>;
export type GlobalGetStartedBox = z.infer<typeof global_get_started_box>;
export type GlobalHeadline = z.infer<typeof global_headline>;
export type GlobalHero = z.infer<typeof global_hero>;
export type GlobalImageContent_1up = z.infer<typeof global_image_content_1up>;
export type GlobalImageHalfScreenHero = z.infer<
  typeof global_image_half_screen_hero
>;
export type GlobalInteractiveCarousel = z.infer<
  typeof global_interactive_carousel
>;
export type GlobalInteractiveGrid = z.infer<typeof global_interactive_grid>;
export type GlobalLogoGrid = z.infer<typeof global_logo_grid>;
export type GlobalMediaGallery = z.infer<typeof global_media_gallery>;
export type GlobalMerchCarousel = z.infer<typeof global_merch_carousel>;
export type GlobalPromoBanner = z.infer<typeof global_promo_banner>;
export type GlobalReasonsToSignup = z.infer<typeof global_reasons_to_signup>;
export type GlobalSeoData = z.infer<typeof global_seo_data>;
export type GlobalShoppableEditorial = z.infer<
  typeof global_shoppable_editorial
>;
export type GlobalSimpleImage = z.infer<typeof global_simple_image>;
export type GlobalStickyCta = z.infer<typeof global_sticky_cta>;
export type GlobalStyleShuffle = z.infer<typeof global_style_shuffle>;
export type GlobalStylistModule = z.infer<typeof global_stylist_module>;
export type GlobalTestGlobalField = z.infer<typeof global_test_global_field>;
export type GlobalTrigallHero = z.infer<typeof global_trigall_hero>;
export type GlobalTripleBlock = z.infer<typeof global_triple_block>;
export type GlobalVideoFullBleedHero = z.infer<
  typeof global_video_full_bleed_hero
>;
export type GlobalVideoHalfScreenHero = z.infer<
  typeof global_video_half_screen_hero
>;
export {
  about,
  careers,
  find_your_perfect_fit,
  gateway,
  gateway_experiment,
  how_it_works,
  kids,
  landing_page_custom,
  men,
  men_big_tall,
  men_experiment,
  modular_page,
  pricing,
  style_persona,
  style_sense,
  stylefile_sharing_page,
  women,
  women_experiment,
  women_jeans,
};
export {
  global_accordion_group,
  global_animated_grid,
  global_business_line_carousel,
  global_content_gallery,
  global_display_carousel,
  global_display_grid,
  global_display_panel,
  global_display_split,
  global_display_text,
  global_divider,
  global_faqs,
  global_footer_image_links,
  global_gallery_hero,
  global_get_started_box,
  global_headline,
  global_hero,
  global_image_content_1up,
  global_image_half_screen_hero,
  global_interactive_carousel,
  global_interactive_grid,
  global_logo_grid,
  global_media_gallery,
  global_merch_carousel,
  global_promo_banner,
  global_reasons_to_signup,
  global_seo_data,
  global_shoppable_editorial,
  global_simple_image,
  global_sticky_cta,
  global_style_shuffle,
  global_stylist_module,
  global_test_global_field,
  global_trigall_hero,
  global_triple_block,
  global_video_full_bleed_hero,
  global_video_half_screen_hero,
};
export { contentStackJSONRichText };
/**
 * This is a map of the uids fields defined in contentTypes to the uids of the global fields they reference.
 *
 */
export const fieldMap = {
  about: {
    seo_data: 'seo_data',
    image_half_screen_hero: 'image_half_screen_hero',
    get_started: 'get_started_box',
  },
  careers: {
    seo_data: 'seo_data',
    triple_block: 'triple_block',
    content_gallery: 'content_gallery',
  },
  find_your_perfect_fit: {
    seo_data: 'seo_data',
    hero: 'trigall_hero',
    how_it_works: 'triple_block',
    content_gallery: 'content_gallery',
    womens_tips_links: 'footer_image_links',
    mens_tips_links: 'footer_image_links',
  },
  gateway: {
    seo_data: 'seo_data',
    video_full_bleed_hero: 'video_full_bleed_hero',
    image_half_screen_hero: 'image_half_screen_hero',
    how_it_works: 'triple_block',
    business_line_carousel: 'business_line_carousel',
    logos: 'logo_grid',
    stylist_module: 'stylist_module',
    reasons_to_signup: 'reasons_to_signup',
    get_started: 'get_started_box',
    promo_banner: 'promo_banner',
    faqs: 'faqs',
  },
  gateway_experiment: {
    seo_data: 'seo_data',
    video_full_bleed_hero: 'video_full_bleed_hero',
    image_half_screen_hero: 'image_half_screen_hero',
    how_it_works: 'triple_block',
    business_line_carousel: 'business_line_carousel',
    logos: 'logo_grid',
    stylist_module: 'stylist_module',
    reasons_to_signup: 'reasons_to_signup',
    get_started: 'get_started_box',
    promo_banner: 'promo_banner',
    faqs: 'faqs',
  },
  how_it_works: {
    seo_data: 'seo_data',
    hero: 'trigall_hero',
    content_gallery: 'content_gallery',
  },
  kids: {
    seo_data: 'seo_data',
    video_half_screen_hero: 'video_half_screen_hero',
    image_half_screen_hero: 'image_half_screen_hero',
    how_it_works: 'triple_block',
    image_content_1up: 'image_content_1up',
    merch_carousel: 'merch_carousel',
    logos: 'logo_grid',
    get_started: 'get_started_box',
  },
  landing_page_custom: {
    seo_data: 'seo_data',
  },
  men: {
    seo_data: 'seo_data',
    video_half_screen_hero: 'video_half_screen_hero',
    image_half_screen_hero: 'image_half_screen_hero',
    how_it_works: 'triple_block',
    merch_carousel: 'merch_carousel',
    logos: 'logo_grid',
    stylist_module: 'stylist_module',
    style_shuffle: 'style_shuffle',
    reasons_to_signup: 'reasons_to_signup',
    get_started: 'get_started_box',
    promo_banner: 'promo_banner',
    faqs: 'faqs',
  },
  men_big_tall: {
    seo_data: 'seo_data',
    trigal_hero: 'trigall_hero',
    why_love_us: 'content_gallery',
    how_it_works: 'triple_block',
    might_also_like_links: 'footer_image_links',
    recommended_links: 'footer_image_links',
  },
  men_experiment: {
    seo_data: 'seo_data',
    video_half_screen_hero: 'video_half_screen_hero',
    image_half_screen_hero: 'image_half_screen_hero',
    how_it_works: 'triple_block',
    merch_carousel: 'merch_carousel',
    logos: 'logo_grid',
    stylist_module: 'stylist_module',
    style_shuffle: 'style_shuffle',
    reasons_to_signup: 'reasons_to_signup',
    get_started: 'get_started_box',
    promo_banner: 'promo_banner',
    faqs: 'faqs',
  },
  modular_page: {
    seo_data: 'seo_data',
  },
  pricing: {
    seo_data: 'seo_data',
    hero: 'trigall_hero',
    how_it_works: 'triple_block',
    about_pricing: 'content_gallery',
  },
  style_persona: {},
  style_sense: {},
  stylefile_sharing_page: {
    seo_data: 'seo_data',
  },
  women: {
    seo_data: 'seo_data',
    video_half_screen_hero: 'video_half_screen_hero',
    image_half_screen_hero: 'image_half_screen_hero',
    how_it_works: 'triple_block',
    merch_carousel: 'merch_carousel',
    logos: 'logo_grid',
    stylist_module: 'stylist_module',
    style_shuffle: 'style_shuffle',
    reasons_to_signup: 'reasons_to_signup',
    get_started: 'get_started_box',
    promo_banner: 'promo_banner',
    faqs: 'faqs',
  },
  women_experiment: {
    seo_data: 'seo_data',
    video_half_screen_hero: 'video_half_screen_hero',
    image_half_screen_hero: 'image_half_screen_hero',
    how_it_works: 'triple_block',
    merch_carousel: 'merch_carousel',
    logos: 'logo_grid',
    stylist_module: 'stylist_module',
    style_shuffle: 'style_shuffle',
    reasons_to_signup: 'reasons_to_signup',
    get_started: 'get_started_box',
    promo_banner: 'promo_banner',
    faqs: 'faqs',
  },
  women_jeans: {
    seo_data: 'seo_data',
    how_it_works: 'triple_block',
    content_gallery: 'content_gallery',
    get_started_box: 'get_started_box',
    might_also_love_links: 'footer_image_links',
    recommended_links: 'footer_image_links',
  },
};
